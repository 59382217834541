<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-container.grid-list-xl(fluid)
                    v-layout(style="align-items: initial;" row wrap justify-center align-center)
                        v-flex(xs12 sm4)
                            .poster-home
                                figure
                                    img(:src="shareUrlM()")
                                v-flex.text-xs-justify(xs12 v-html="hometext in profile.network && !profile.network.hometext.trim().length ? hometext : profile.network.hometext")
                            .poster-home.mb-2
                                figure(v-for="(banner, i) in banners" :key="'bn'+i")
                                    img(:src="imgurl + banner.image + '?time=' + banner.time")
                                    a(v-if="banner.url != null" :href="banner.url" target="_blank") {{ $t('Ver enlace') }}
                        v-flex(xs12 sm8)
                            v-tabs(v-model="tab")
                                v-tab {{ $t('Inicio')}}
                                v-tab {{ $t('Mi red')}}
                                v-tab {{ $t('Mapa de red')}}
                            v-tabs-items(v-model="tab")
                                v-tab-item.pa-3(:value="0")
                                    .btns.btns-right
                                        v-btn(small color="primary" @click="$router.push({path: '/gallery-s'})") {{ $t("Ver galería") }}
                                        v-btn(small color="primary" @click="$refs.manageGallery.open('add', $t('Agregar imagen'))") {{ $t("Agregar imagen a la galería") }}
                                        v-btn(small color="primary" v-if="profile.network.standregister" @click="$refs.stand.open(profile.network)")
                                            v-icon.mr-1 home_filled
                                            | {{ $t("Casas de registro") }}
                                    template(v-if="highlightNetworks.length")
                                        h2.mt-4 {{ $t('Redes destacadas') }}
                                        v-layout(row wrap)
                                            v-flex(xs12 sm6 v-for="(highlight, i) in highlightNetworks" :key="'h-block'+i")
                                                v-list.pt-0(dense='')
                                                    v-list-tile.network-tile(v-for="(network, i) in highlight" :key="'h-network'+i")
                                                        v-list-tile-avatar
                                                            img(v-if="shareAvatarUrl(network).length" :src="shareAvatarUrl(network)")
                                                            v-gravatar(v-else :email="network.alias")
                                                        v-list-tile-content
                                                            v-list-tile-title(style="height:38px;")
                                                                | {{ network.name }}
                                                        v-list-tile-action
                                                            v-btn.primary(v-if="!network.registered" small depressed round @click.prevent="registerNetwork(network)" :loading="network.loader") {{$t('Unirme')}}
                                                            v-btn.primary(v-else small depressed round @click.prevent="goNetwork(network)" :loading="network.loader") {{$t('Ingresar')}}

                                    h2.no-bold.mb-4.my-4
                                        b {{ profile.names }}
                                        v-avatar.mx-2.grey.lighten-4(size='40')
                                            img(v-if="photoUrl(profile.photo).length" :src="photoUrl(profile.photo)")
                                            v-gravatar(v-else :email="profile.email")
                                        router-link(to="/account"): small {{ $t('Editar foto') }}
                                    h2.no-bold.mb-4(v-if="(profile.referid != null)")
                                        | {{ $t('Invitado por') }} 
                                        b {{ profile.refer || $t('Nombre del referido') }} 
                                            v-avatar.mx-2.grey.lighten-4(size='40')
                                                img(v-if="photoUrl(profile.refer_photo).length" :src="photoUrl(profile.refer_photo)")
                                                v-gravatar(v-else :email="profile.email")
                                    template(v-if="isNetwork(['joaquingobernador'])")
                                        h2.no-bold
                                            | {{ $t('Ya sos parte de nuestra red de apoyo') }} 
                                            b {{ profile.network.name }}
                                            | .
                                        h2.no-bold.mt-4.mb-4 {{$t('Ahora podés hacer crecer tu red.')}} 
                                            router-link(to="/share") {{$t('!Invitá')}}
                                            | &nbsp;{{$t('a todos tus amigos, familiares y conocidos de WhatsApp que quieras que se sumen!')}}.
                                    template(v-else)
                                        h2.no-bold
                                            | {{ $t('Ya eres parte de nuestra red de mutuo apoyo') }} 
                                            b {{ profile.network.name }}
                                            | .
                                        h2.no-bold.mt-4.mb-4 {{$t('Ahora haz crecer tu red,')}} 
                                            router-link(to="/share") {{$t('INVITA')}}
                                            | &nbsp;{{$t('a todos los amigos y amigas de WhatsApp que conozcas cada día')}}.
                                    .share-content.mt-3.mb-2
                                        .btns.btns-center
                                            v-btn.btn-whatsapp(@click.native="shareWp" large color="secondary light--text") 
                                                img(src="/public/img/icons/whatsapp-icon.svg" :alt="$t('Whatsapp')")
                                                | {{$t('INVITAR AMIGOS A MI RED')}}
                                    template(v-if="isNetwork(['joaquingobernador'])")
                                        h2.no-bold.mt-4.mb-4 {{$t('Preguntá')}} 
                                            a(href="" @click.prevent="shareWpNetwork") {{$t('acá por WhatsApp')}} 
                                            | {{$t('todo lo que quieras saber sobre la red de apoyo')}} {{ profile.network.name }}, {{$t('Podés compartirnos ideas para sumar a más gente a la red')}}.
                                        v-divider.grey.mt-4.x1
                                        //h2.no-bold.mt-4.mb-4 {{$t('Mientras más invitados tengas, más cerca estamos de poner orden en la provincia de Buenos Aires')}}.
                                    template(v-else)
                                        h2.no-bold.mt-4.mb-4 {{$t('Pregunta')}} 
                                            a(href="" @click.prevent="shareWpNetwork") {{$t('aquí por WhatsApp')}} 
                                            | {{$t('todo lo que quieras saber sobre')}} {{ profile.network.name }}, {{$t('cuéntanos quién eres, a qué te dedicas, y danos ideas para alcanzar nuestro máximo potencial junto a ti')}}.
                                        v-divider.grey.mt-4.x1
                                        h2.no-bold.mt-4.mb-4 {{$t('Más invitados tendrás y así lograrás subir en todos tus rankings')}}.
                                    template(v-if="last.length")
                                        b {{$t('Últimos amigos registrados')}}
                                        v-list.mt-2.mb-4(three-line)
                                            v-list-tile.my-2(v-for="(last, i) in last" :key="'la'+i")
                                                v-list-tile-avatar
                                                    v-avatar.grey.lighten-4(size='27')
                                                        img(v-if="photoUrl(last.photo).length" :src="photoUrl(last.photo)")
                                                        v-gravatar(v-else :email="last.email")
                                                v-list-tile-content
                                                    v-list-tile-title.primary--text(@click="fetchDetailChart(last.uuid, last)") 
                                                        span(v-if="last.loadChart") {{$t('Cargando')}}...
                                                        span(v-else) {{ last.names }}
                                                    v-list-tile-sub-title(style="color:#444") {{$t('Fecha y hora de registro')}}: 
                                                    v-list-tile-sub-title(style="color:#444") {{ last.datecreated | moment('YYYY-MM-DD HH:mm') }}
                                        .btns.btns-center(v-if="totalLast != last.length")
                                            v-btn.primary(@click="viewMoreLast" :loading="preloadLast" small depressed) {{$t('Ver más')}}
                                v-tab-item.pa-3(:value="1")
                                    template(v-if="tab == 1")
                                        .user-title.mt-4
                                            .medal
                                                template(v-if="preload")
                                                    v-progress-circular.text-xs-center(:size="24" indeterminate color="primary")
                                                img(v-if="rank == 1", src="/public/img/icons/medalla-1.png")
                                                img(v-else-if="rank == 2", src="/public/img/icons/medalla-2.png")
                                                img(v-else-if="rank == 3", src="/public/img/icons/medalla-3.png")
                                                template(v-else)
                                                    img(src="/public/img/icons/medalla-simple.png")
                                                    strong {{ rank }}
                                            .user-title-content(style="margin-top: 20px;")
                                                h1.secondary--text {{ profile.names }}
                                                small.grey--text {{$t('Ranking')}} {{ profile.rank }}
                                        h2.mt-4.no-bold(v-if="(profile.network.isgoal || profile.isgoal)") {{$t('Tu')}} 
                                            a(style="text-decoration:underline;" @click="openGoal") 
                                                span(v-if="loadGoalText") {{$t('reto')}}(...)
                                                span(v-else) {{$t('reto')}}
                                            |  {{$t('es crecer en tus rankings')}}, 
                                            a(style="text-decoration:underline;" @click="openGoal") 
                                                span(v-if="loadGoalText") {{$t('día a día')}}(...)
                                                span(v-else) {{$t('día a día')}}
                                            | , 
                                            a(style="text-decoration:underline;" @click="openGoal") 
                                                span(v-if="loadGoalText") {{$t('día a día')}}(...)
                                                span(v-else) {{$t('semana a semana')}}
                                            | &nbsp;y 
                                            a(style="text-decoration:underline;" @click="openGoal") 
                                                span(v-if="loadGoalText") {{$t('día a día')}}(...)
                                                span(v-else) {{$t('mes a mes')}}
                                            | .
                                        template(v-if="chartData.nodes.length && Object.keys(currentNode).length")
                                            h2.mt-4 {{$t('Mi red')}}
                                            template(v-if="!loadingCurrentNode && Object.keys(currentNode).length")
                                                v-layout.mt-2.mb-2(row wrap)
                                                    v-flex.pa-0(sm12)
                                                        v-btn.primary.mx-0.mt-0.subchart-btn(:loading="loadRank" small depressed @click="openRank(currentNode.uuid)")
                                                            v-icon(left small) star
                                                            | {{$t('Ver clasificaciones')}}
                                                    v-flex.pa-0(sm12)
                                                        v-btn.primary.mx-0.mt-0.subchart-btn(small depressed @click="openSubchartFnParents")
                                                            v-icon(left small) fingerprint
                                                            | {{$t('Ver distribución genealógica')}}
                                                    v-flex.pa-0(sm12 v-if="!genesis")
                                                        v-btn.primary.mx-0.mt-0.subchart-btn(small depressed @click="openSubchartFn")
                                                            v-icon(left small) pie_chart
                                                            | {{$t('Ver distribución de referidos')}}
                                            v-layout(row wrap)
                                                v-flex(xs12 sm4)
                                                    template(v-if="loadingCurrentNode")
                                                        v-progress-circular.mx-auto.text-xs-center(small indeterminate color="primary")
                                                    div.graph-detail(v-else)
                                                        h4 {{$t('Posición')}}: 
                                                            b(v-if="(currentNode.rank) > 0") {{ (currentNode.rank) }}
                                                        h4 {{$t('Nombre')}}:
                                                            v-avatar.mx-1(:size="16")
                                                                img(v-if="photoUrl(currentNode.photo).length" :src="photoUrl(currentNode.photo)")
                                                                v-gravatar(v-else-if="Object.keys(currentNode).length" :email="currentNode.email") 
                                                            | {{ currentNode.names || currentNode.name }}
                                                        h4 {{$t('Correo electrónico')}}: {{ currentNode.email }}
                                                        h4 {{$t('Referidos')}}: {{ currentNode.totalshare }}
                                                        h4 {{$t('Red')}}: {{ currentNode.total }}
                                                        h4 {{$t('Nivel')}}: {{ currentNode.level }}
                                                        h4(v-if="isAvailable('birthdate')") {{$t('Edad')}}: {{ currentNode.age }}
                                                        h4(v-if="isAvailable('gender')") {{$t('Género')}}: {{ currentNode.gender }}
                                                        h4(v-if="isAvailable('cityid')") {{$t('Ciudad')}}: {{ currentNode.city }}
                                                        h4(v-if="isAvailable('zones')") {{$t('Zona')}}: {{ currentNode.zone }}
                                                        h4(v-if="currentNode.whatsapp.length"): a(target="blank" :href="currentNode.whatsapp") {{$t('Escribir al WhatsApp')}}
                                                        v-divider.primary.my-2
                                                        h3.mb-2.mt-3 {{$t('Posiciones')}}
                                                        h4(v-if="isAvailable('countryid')") {{ currentNode.country }}: 
                                                            b {{ currentNode.rank_country }}
                                                        h4(v-if="isAvailable('stateid')") {{ currentNode.state }}: 
                                                            b {{ currentNode.rank_state }}
                                                        h4(v-if="isAvailable('cityid')") {{ currentNode.city }}: 
                                                            b {{ currentNode.rank_city }}
                                                        template(v-if="isAvailable('zones')")
                                                            h4(v-for="(zone, i) in currentNode.rank_zones" :key="'zr'+i")
                                                                | {{ zone.zone }}: 
                                                                b {{ zone.rank }}
                                                        color-resume(:colors="colors" :type="color_type")
                                                v-flex.text-xs-center(xs12 sm8)
                                                    v-tabs(
                                                        v-if="!parents"
                                                        v-model="chartTab"
                                                        show-arrows)
                                                        v-tabs-slider
                                                        v-tab {{ $t('Todos') }}
                                                        v-tab {{ $t('Estado') }}
                                                        v-tab {{ $t('Ciudad') }}
                                                        v-tab {{ $t('Género') }}
                                                        v-tab {{ $t('Edad') }}
                                                        v-tab {{ $t('Zona') }}                                
                                                        v-tab {{ $t('Formularios') }}
                                                        v-tab {{ $t('Niveles') }}
                                                        v-tabs-items(v-model="chartTab")
                                                            v-tab-item
                                                                template(v-if="chartTab == 0")
                                                                    v-layout.mt-2.mb-2(row wrap)
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                        .btns.btns-right
                                                                            v-btn(color="primary" small @click="fetchGraph") {{$t('Filtrar')}}
                                                                            v-btn(color="secondary" small @click="resetGraph") {{$t('Restablecer')}}
                                                                    template(v-if="preloadChart")
                                                                        v-progress-circular(indeterminate color="primary")
                                                                    template(v-else)
                                                                        div.chartdiv(v-if="!chartData.nodes.length")
                                                                            | {{$t('No se han encontrado datos')}}
                                                                        div.chartdiv(:id="'chartdiv'+_uid" v-else)
                                                            //- Estados
                                                            v-tab-item
                                                                template(v-if="chartTab == 1")
                                                                    v-layout.mt-2.mb-2(row wrap)
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                        .btns.btns-right
                                                                            v-btn(color="primary" small @click="fetchFilterGraph(stateOpts)") {{$t('Filtrar')}}
                                                                            v-btn(color="secondary" small @click="resetFilterGraph(stateOpts)") {{$t('Restablecer')}}
                                                                    div.my-3
                                                                        template(v-if="preloadChartState")
                                                                            v-progress-circular(indeterminate color="primary")
                                                                        template(v-else)
                                                                            div.chartdiv(v-if="!chartStateData.nodes.length")
                                                                                | {{$t('No se han encontrado datos') }}
                                                                            div.chartdiv(:id="'chartstatediv'+_uid" v-else)
                                                            v-tab-item
                                                                template(v-if="chartTab == 2")
                                                                    v-layout.mt-2.mb-2(row wrap)
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                        .btns.btns-right
                                                                            v-btn(color="primary" small @click="fetchFilterGraph(cityOpts)") {{$t('Filtrar')}}
                                                                            v-btn(color="secondary" small @click="resetFilterGraph(cityOpts)") {{$t('Restablecer')}}
                                                                    div.my-3
                                                                        template(v-if="preloadChartCity")
                                                                            v-progress-circular(indeterminate color="primary")
                                                                        template(v-else)
                                                                            div.chartdiv(v-if="!chartCityData.nodes.length")
                                                                                | {{$t('No se han encontrado datos') }}
                                                                            div.chartdiv(:id="'chartcitydiv'+_uid" v-else)
                                                            v-tab-item
                                                                template(v-if="chartTab == 3")
                                                                    v-layout.mt-2.mb-2(row wrap)
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                        .btns.btns-right
                                                                            v-btn(color="primary" small @click="fetchFilterGraph(genderOpts)") {{$t('Filtrar')}}
                                                                            v-btn(color="secondary" small @click="resetFilterGraph(genderOpts)") {{$t('Restablecer')}}
                                                                    div.my-3
                                                                        template(v-if="preloadChartGender")
                                                                            v-progress-circular(indeterminate color="primary")
                                                                        template(v-else)
                                                                            div.chartdiv(v-if="!chartGenderData.nodes.length")
                                                                                | {{$t('No se han encontrado datos') }}
                                                                            div.chartdiv(:id="'chartgenderdiv'+_uid" v-else)
                                                            v-tab-item
                                                                template(v-if="chartTab == 4")
                                                                    v-layout.mt-2.mb-2(row wrap)
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                        .btns.btns-right
                                                                            v-btn(color="primary" small @click="fetchFilterGraph(ageOpts)") {{$t('Filtrar')}}
                                                                            v-btn(color="secondary" small @click="resetFilterGraph(ageOpts)") {{$t('Restablecer')}}
                                                                    div.my-3
                                                                        template(v-if="preloadChartAge")
                                                                            v-progress-circular(indeterminate color="primary")
                                                                        template(v-else)
                                                                            div.chartdiv(v-if="!chartAgeData.nodes.length")
                                                                                | {{$t('No se han encontrado datos') }}
                                                                            div.chartdiv(:id="'chartagediv'+_uid" v-else)
                                                            v-tab-item
                                                                template(v-if="chartTab == 5")
                                                                    v-layout.mt-2.mb-2(row wrap)
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                        v-flex(xs12 sm6)
                                                                            v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                    v-spacer
                                                                                    v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                    v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                        .btns.btns-right
                                                                            v-btn(color="primary" small @click="fetchFilterGraph(zoneOpts)") {{$t('Filtrar')}}
                                                                            v-btn(color="secondary" small @click="resetFilterGraph(zoneOpts)") {{$t('Restablecer')}}
                                                                    div.my-3
                                                                        template(v-if="preloadChartZone")
                                                                            v-progress-circular(indeterminate color="primary")
                                                                        template(v-else)
                                                                            div.chartdiv(v-if="!chartZoneData.nodes.length")
                                                                                | {{$t('No se han encontrado datos') }}
                                                                            div.chartdiv(:id="'chartzonediv'+_uid" v-else)
                                                            // Formularios
                                                            v-tab-item
                                                                template(v-if="chartTab == 6")
                                                                    div.my-3(v-if="preloadFormGraph")
                                                                        v-progress-circular(indeterminate color="primary")
                                                                    template(v-else)
                                                                        v-layout.mt-2.mb-2(row wrap)
                                                                            v-flex(xs12 sm6)
                                                                                v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                    v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                    v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                        v-spacer
                                                                                        v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                        v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                            v-flex(xs12 sm6)
                                                                                v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                    v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                    v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                        v-spacer
                                                                                        v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                        v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                            v-flex(xs12 sm6)
                                                                                v-select(
                                                                                    v-model="filter.graph"
                                                                                    :items='formgraphs',
                                                                                    item-value="uuid"
                                                                                    item-text="label",
                                                                                    :label='$t("Gráfico formulario personalizado")',
                                                                                    append-icon="keyboard_arrow_down",
                                                                                    autocomplete
                                                                                    :filter="vFilter"
                                                                                )
                                                                            .btns.btns-right
                                                                                v-btn(color="primary" small @click="fetchFilterGraph(formOpts)") {{$t('Filtrar')}}
                                                                                v-btn(color="secondary" small @click="resetFilterGraph(formOpts)") {{$t('Restablecer')}}
                                                                        div.my-3
                                                                            template(v-if="preloadChartForm")
                                                                                v-progress-circular(indeterminate color="primary")
                                                                            template(v-else)
                                                                                div.chartdiv(v-if="!chartFormData.nodes.length")
                                                                                    | {{$t('No se han encontrado datos') }}
                                                                                div.chartdiv(:id="'chartformdiv'+_uid" v-else)
                                                            // Niveles
                                                            v-tab-item
                                                                template(v-if="chartTab == 7")
                                                                    div.my-3(v-if="preloadLevelGraph")
                                                                        v-progress-circular(indeterminate color="primary")
                                                                    template(v-else)
                                                                        v-layout.mt-2.mb-2(row wrap)
                                                                            v-flex(xs12 sm6)
                                                                                v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                                                                                    v-text-field(slot='activator' :label='$t("Desde")' v-model='filter.from' readonly)
                                                                                    v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                                                                                        v-spacer
                                                                                        v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                                                                                        v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{$t('OK')}}
                                                                            v-flex(xs12 sm6)
                                                                                v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                                                                                    v-text-field(slot='activator' :label='$t("Hasta")' v-model='filter.to' readonly)
                                                                                    v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                                                                                        v-spacer
                                                                                        v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                                                                                        v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{$t('OK')}}
                                                                            .btns.btns-right
                                                                                v-btn(color="primary" small @click="fetchFilterGraph(formOpts)") {{$t('Filtrar')}}
                                                                                v-btn(color="secondary" small @click="resetFilterGraph(formOpts)") {{$t('Restablecer')}}
                                                                        div.my-3
                                                                            template(v-if="preloadChartLevel")
                                                                                v-progress-circular(indeterminate color="primary")
                                                                            template(v-else)
                                                                                div.chartdiv(v-if="!chartLevelData.nodes.length")
                                                                                    | {{$t('No se han encontrado datos') }}
                                                                                div.chartdiv(:id="'chartleveldiv'+_uid" v-else)
                                                    template(v-else)
                                                        template(v-if="preloadChart")
                                                            v-progress-circular(indeterminate color="primary")
                                                        template(v-else)
                                                            div.chartdiv(v-if="!chartData.nodes.length")
                                                                | {{$t('No se han encontrado datos') }}
                                                            div.chartdiv(:id="'chartdiv'+_uid" v-else)
                                v-tab-item.pa-3(:value="2")
                                    template(v-if="tab == 2")
                                        v-layout(row wrap)
                                            w-map(:refer="$root.profile" :isReferral="true" :parentFilter="filter" @new-map="referMap" @clean-children-map="cleanChildrenReferMap")
                                        v-layout.my-5(row wrap v-for="(map, i) in maps" :key="'map'+i")
                                            w-map(:parentFilter="filter" @reset-map="resetReferMap" @new-map="referMap" :refer="map.item" :currentMapIndex="i" @clean-children-map="cleanChildrenReferMap")
            wp-confirm
            rank(ref="rank")
            goal(ref="goal")
            chart(ref="chart" :parents="isParents")
            manage-gallery(ref="manageGallery")
            notes(ref="notes")
            stand-list(ref="stand")
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import fn from 'mixins/fn'
    import ColorResume from 'components/colors/Resume'
    import ManageGallery from 'components/ManageGallery'
    import Map from 'components/Map'
    import StandList from 'components/stand/StandList'

    export default {
        components: {
            'color-resume' : ColorResume,
            'w-map' : Map,
            ManageGallery,
            StandList
        },
        mixins: [auth, fn],
        metaInfo() {
            return {
                title: this.$t('Inicio')
            }
        },
        data(){
            return {
                preload: false,
                preloadLast: false,
                loadRank: false,
                rank: '',
                hometext: '',
                items: [],
                goals: [],
                loadRankText: false,
                loadGoalText: false,
                limitLast: 5,
                offsetLast: 0,
                totalLast: 0,
                last: [],
                loadChart: false,
                preloadChart: false,
                chart: false,
                genesisNode: {},
                currentNode: {},
                loadingCurrentNode: false,
                isParents: false,
                genesis: true,
                chartData: {
                    nodes: []
                },
                sharewp :'',
                colors  : [],

                // Mapas
                filterSchema: {
                    query   : '',
                    from    : null,
                    to      : null,
                    subadmin: [],
                },
                filter: {},
                maps: [],

                // Tabs
                tab: 0,

                // Gráficos
                parents: false,
                modalDateStart: false,
                modalDateEnd: false,
                chartTab: 0,
                preloadChartGender: false,
                chartGenderData: {
                    nodes: []
                },
                preloadChartState: false,
                chartStateData: {
                    nodes: []
                },
                preloadChartCity: false,
                chartCityData: {
                    nodes: []
                },
                preloadChartZone: false,
                chartZoneData: {
                    nodes: []
                },
                preloadChartAge: false,
                chartAgeData: {
                    nodes: []
                },
                stateOpts: {
                    preload : 'preloadChartState', 
                    data    : 'chartStateData', 
                    chart   : 'chartstatediv', 
                    type    : 1
                },
                cityOpts: {
                    preload : 'preloadChartCity', 
                    data    : 'chartCityData', 
                    chart   : 'chartcitydiv', 
                    type    : 2
                },
                zoneOpts: {
                    preload : 'preloadChartZone', 
                    data    : 'chartZoneData', 
                    chart   : 'chartzonediv', 
                    type    : 5
                },
                genderOpts: {
                    preload : 'preloadChartGender', 
                    data    : 'chartGenderData', 
                    chart   : 'chartgenderdiv', 
                    type    : 3
                },
                ageOpts: {
                    preload : 'preloadChartAge', 
                    data    : 'chartAgeData', 
                    chart   : 'chartagediv', 
                    type    : 4
                },
                formgraphs: [],
                formOpts: {
                    preload : 'preloadChartForm', 
                    data    : 'chartFormData', 
                    chart   : 'chartformdiv', 
                    type    : 6
                },
                preloadChartForm: false,
                chartFormData: {
                    nodes: []
                },
                preloadFormGraph: false,

                // Niveles
                preloadLevelGraph: false,
                levelOpts: {
                    preload : 'preloadChartLevel', 
                    data    : 'chartLevelData', 
                    chart   : 'chartleveldiv', 
                    type    : 7
                },
                preloadChartLevel: false,
                chartLevelData: {
                    nodes: []
                },
                preloadHightLight: false,
                highlightNetworks: [],

                // Banners
                preloadBanner: false,
                banners: []
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            }
        },
        watch:{
            tab: {
                inmmediate: true,
                handler(){

                    // Inicio
                    if(this.tab == 0){
                        
                        this.last = []
                        this.highlightNetworks = []
                        this.fetchHighLight()
                        this.fetchRank()
                        this.fetchLast()
                        this.fetchShareWp()
                    }

                    // Gráfico
                    if(this.tab == 1){

                        this.filter      = Object.assign({}, this.filterSchema)
                        this.colors      = []
                        this.chartTab    = 0
                        this.color_type  = 0
                        this.uuid        = this.profile.uuid
                        
                        this.fetchDetailChart()
                    }

                    // Mapas
                    if(this.tab == 2){
                        
                        // Mapas
                        this.filter = Object.assign({}, this.filterSchema)
                    }
                }
            },
            chartTab(){
            
                this.colors     = []
                this.color_type = 0

                if(this.chartTab == 0){
                    this.resetGraph()
                }
                if(this.chartTab == 1){
                    this.resetFilterGraph(this.stateOpts)
                }
                if(this.chartTab == 2){
                    this.resetFilterGraph(this.cityOpts)
                }
                if(this.chartTab == 5){
                    this.resetFilterGraph(this.zoneOpts)
                }
                if(this.chartTab == 3){
                    this.resetFilterGraph(this.genderOpts)
                }
                if(this.chartTab == 4){
                    this.resetFilterGraph(this.ageOpts)
                }
                if(this.chartTab == 6){
                    this.fetchFormGraphs()
                }
                if(this.chartTab == 7){
                    this.resetFilterGraph(this.levelOpts)
                }
            }
        },
        computed: {
            profile(){
                return this.$root.profile
            },
            networkText(){
                return this.profile.network.text
            },
            inviteURL(){
                return this.profile.networkUrl
            },
            networkURL(){
                return window.encodeURIComponent(this.networkText + ' ' + this.inviteURL)
            },
            imgurl(){
                return config.uploadbanner
            }
        },
        created(){
            this.fetchBanner()
        },
        methods:{

            // Mapas
            cleanChildrenReferMap(index){

                if(index == -1){
                    this.maps = []
                }

                this.maps = this.maps.slice(0, index + 1)
            },

            referMap(item){

                if(this.maps.some(m => m.item.uuid == item.uuid)){
                    return
                }

                this.maps.push({
                    item
                })
            },

            resetReferMap(index){
                this.maps = this.maps.slice(0, index)
            },

            viewMoreLast(){

                this.offsetLast += 5
                this.fetchLast()
            },
            shareUrlM(){
                return this.profile.network ? this.profile.network.share && (this.profile.network.share != null && this.profile.network.share != '') ? config.uploadurl + this.profile.network.share + '?time=' + this.profile.network.time : config.imgurl : config.imgurl
            },
            photoUrl(photo){

                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },
            openRank(uuid){

                this.loadRank = true
                this.loadRankText = true
                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-rank', {
                        params: {
                            uuid,
                            key: 'ever'
                        }
                    }).then((r) => {

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.loadRankText = false
                        this.loadRank = false
                        this.$refs.rank.open(Object.assign(this._.cloneDeep(this.$root.profile), itemData))

                    }).catch(() => {
                        this.loadRankText = false
                        this.loadRank = false
                    })
                })
            },
            openGoal(){

                this.loadGoalText = true
                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail-goal?uuid='+this.$root.profile.uuid).then((r) => {

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.loadGoalText = false
                        this.$refs.goal.open(itemData)

                    }).catch(() => {
                        this.loadGoalText = false
                    })
                })
            },
            fetchRank() {
                this.preload = true
                this.$api(this, (xhr) => {
                    xhr.post('/profile/rank').then((r) => {

                        let data = r.data
                        if (data.response) {
                            this.preload = false
                            this.rank = data.data
                        }
                    }).catch(() => {})
                })
            },
            /**
             * Obtener redes destacadas
             */
            fetchHighLight() {

                this.preloadHightLight = true
                this.$api(this, (xhr) => {
                    xhr.get('/highlight-network/home').then((r) => {

                        let data = r.data
                        this.preloadHightLight = false
                        
                        if (data.response) {
                            this.highlightNetworks = data.data
                        }

                    }).catch(() => {
                        this.preloadHightLight = false
                    })
                })
            },
            fetchLast() {

                this.preloadLast = true
                this.$api(this, (xhr) => {
                    xhr.get('/report/network/last', {
                        params: {
                            limit  : this.limitLast,
                            offset : this.offsetLast
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {
                            
                            this.preloadLast = false
                            this.totalLast = data.data.total

                            let last = data.data.result.map((data) => {
                                data.loadChart = false
                                return data
                            })

                            this.last = this.last.concat(last)
                        }
                    }).catch(() => {})
                })
            },
            fetchConfig() {
                this.$api(this, (xhr) => {
                    xhr.get('/settings/hometext').then((r) => {

                        let data = r.data
                        if (data.response) {
                            this.hometext = data.data
                        }
                    }).catch(() => {})
                })
            },
            fetchShareWp() {
                this.$api(this, (xhr) => {
                    xhr.get('/settings/sharewp').then((r) => {

                        let data = r.data
                        if (data.response) {
                            this.sharewp = data.data
                        }
                    }).catch(() => {})
                })
            },
            shareWpNetwork(){
                window.open(
                    'https://wa.me/' + this.profile.network.whatsapp,
                    'popup'
                )
            },
            shareWp(){
                window.open(this.sharewp, 'popup')
            },
            myNetwork(item){

                this.preloadChart = true
                this.chart = true

                this.genesisNode      = this._.cloneDeep(item)
                this.genesisNode.zone = this.genesisNode.zones ? this.genesisNode.zones.map((z) => z.division+'-'+z.name).join(', ') : this.genesisNode.zone
                this.currentNode      = this._.cloneDeep(item)

                let url = '/report/network/graph?uuid='

                this.$api(this, (xhr) => {
                    xhr.get(url+(item.uuid || item.id)).then((r) => {

                        let data = r.data
                        this.preloadChart = false
                        this.chartData = data.data

                        if(this.chartData.hasOwnProperty('colors')){
                            this.colors = this.chartData.colors
                        }

                        if(data.response){
                            this.$nextTick(() => this.makeChart(this.chartData))
                        }

                    }).catch(() => this.preloadChart = false)
                })
            },
            makeChart(data){

                if(!data.nodes.length){
                    return
                }

                let chartdiv = document.getElementById('chartdiv'+this._uid)
                let w = chartdiv.offsetWidth
                let h = chartdiv.offsetHeight

                let Graph = ForceGraph()
                (chartdiv)
                    .graphData(data)
                    .width(w)
                    .height(h)
                    .nodeId('id')
                    .nodeVal('val')
                    .nodeLabel('name')
                    .nodeAutoColorBy('group')
                    .linkSource('source')
                    .linkTarget('target')

                Graph.nodeCanvasObject((node, ctx, globalScale, isShadow) => {

                    const padAmount = isShadow / globalScale
                    const r = Math.sqrt(Math.max(0, node.val || 1)) * Graph.nodeRelSize() + padAmount

                    ctx.beginPath()
                    ctx.arc(node.x, node.y, r, 0, 2 * Math.PI, false)

                    ctx.fillStyle = node.color
                    ctx.lineWidth = 1
                    ctx.strokeStyle = 'rgb(193 193 193)'
                    ctx.fill()
                    ctx.stroke()
                })

                Graph.d3Force('link').strength(() => 0.5)
                Graph.onNodeClick((node) => {

                    this.loadingCurrentNode = true
                    if(node.id == 1){
                        this.genesis = true
                        this.currentNode = this._.cloneDeep(this.genesisNode)
                        this.loadingCurrentNode = false
                        return
                    }

                    this.$api(this, (xhr) => {
                        xhr.get('/report/network/graph/detail?uuid='+node.id).then((r) => {

                            let data = r.data
                            this.currentNode = this._.cloneDeep(data.data)
                            this.loadingCurrentNode = false
                            this.genesis = false

                        }).catch(() => {
                            this.currentNode = {}
                            this.loadingCurrentNode = false
                        })
                    })
                })
            },
            openSubchartFn(){

                this.isParents = false
                this.$refs.chart.openSubchart = true
                this.$nextTick(() => {
                    this.$refs.chart.open(this.currentNode)
                })
            },
            openSubchartFnParents(){

                this.isParents = true
                this.$refs.chart.openSubchart = true
                this.$nextTick(() => {
                    this.$refs.chart.open(this.currentNode)
                })
            },
            fetchDetailChart(uuid = null, item={}){

                let uid = this.profile.uuid
                if(uuid != null){
                    uid = uuid 
                    item['loadChart'] = true
                }else{
                    this.loadChart = true
                }
                
                this.$api(this, (xhr) => {
                    xhr.get('/report/network/graph/detail?uuid='+uid).then((r) => {

                        if(uuid != null){
                            item['loadChart'] = false
                        }else{
                            this.loadChart = false
                        }

                        let data = r.data
                        let itemData = this._.cloneDeep(data.data)
                        this.availableRow = data.data.available || []

                        if(uuid != null){
                            this.$refs.chart.open(itemData)
                        }else{
                            this.myNetwork(itemData)
                        }

                    }).catch(() => {
                        if(uuid != null){
                            item['loadChart'] = false
                        }else{
                            this.loadChart = false
                        }
                    })
                })
            },
            resetGraph(){

                this.filter = this._.cloneDeep(this.filterSchema)
                this.fetchGraph()
            },
            fetchGraph(){

                this.preloadChart = true

                let url = '/report/network/graph?uuid='
                if(this.parents){
                    url = '/report/network/graph/parent?uuid='
                }

                this.$api(this, (xhr) => {
                    xhr.get(url + this.uuid, {
                        params: {
                            filter: JSON.stringify(this.filter)
                        }
                    }).then((r) => {

                        let data = r.data
                        this.preloadChart = false
                        this.chartData = data.data

                        if(this.chartData.hasOwnProperty('colors')){
                            this.colors = this.chartData.colors
                        }

                        if(data.response){
                            this.$nextTick(() => this.makeChart(this.chartData))
                        }

                    }).catch(() => this.preloadChart = false)
                })
            },
            resetFilterGraph(options){

                this.filter = this._.cloneDeep(this.filterSchema)
                this.fetchFilterGraph(options)
            },
            fetchFilterGraph({preload, data, chart, type}){

                this[preload] = true

                let url = '/report/network/graph?uuid='
                this.$api(this, (xhr) => {
                    xhr.get(url + this.uuid + '&type=' + type, {
                        params: {
                            filter: JSON.stringify(this.filter)
                        }
                    }).then((r) => {

                        let dat       = r.data
                        this[preload] = false
                        this[data]    = dat.data

                        if(this[data].hasOwnProperty('colors')){
                            this.colors = this[data].colors
                        }

                        if(this[data].hasOwnProperty('type')){
                            this.color_type = parseInt(this[data].type)
                        }

                        if(dat.response){
                            this.$nextTick(() => this.makeFilterChart({data, chart}))
                        }

                    }).catch(() => this[preload] = false)
                })
            },
            makeFilterChart({chart, data}){

                if(!this[data].nodes.length){
                    return
                }

                let chartdiv = document.getElementById(chart + this._uid)
                let w = chartdiv.offsetWidth
                let h = chartdiv.offsetHeight

                let Graph = ForceGraph()
                (chartdiv)
                    .graphData(this[data])
                    .width(w)
                    .height(h)
                    .nodeId('id')
                    .nodeVal('val')
                    .nodeLabel((n) => {
                        return `<b>${n.label}</b></br> ${n.name}`
                    })
                    .nodeAutoColorBy('group')
                    .linkSource('source')
                    .linkTarget('target')

                Graph.nodeCanvasObject((node, ctx, globalScale, isShadow) => {

                    const padAmount = isShadow / globalScale
                    const r = Math.sqrt(Math.max(0, node.val || 1)) * Graph.nodeRelSize() + padAmount

                    ctx.beginPath()
                    ctx.arc(node.x, node.y, r, 0, 2 * Math.PI, false)

                    ctx.fillStyle = node.color
                    ctx.lineWidth = 1
                    ctx.strokeStyle = 'rgb(193 193 193)'
                    ctx.fill()
                    ctx.stroke()
                })

                Graph.d3Force('link').strength(() => 0.5)
                Graph.onNodeClick((node) => {

                    this.loadingCurrentNode = true
                    if(node.id == 1){
                        this.genesis = true
                        this.currentNode = this._.cloneDeep(this.genesisNode)
                        this.loadingCurrentNode = false
                        return
                    }

                    this.$api(this, (xhr) => {
                        xhr.get('/report/network/graph/detail?uuid='+node.id).then((r) => {

                            let data = r.data
                            this.currentNode = this._.cloneDeep(data.data)
                            this.availableRow = data.data.available || []
                            this.loadingCurrentNode = false
                            this.genesis = false

                        }).catch(() => {
                            this.currentNode = {}
                            this.loadingCurrentNode = false
                        })
                    })
                })
            },
            fetchCountry(){

                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.countries = data.data
                        }
                    }).catch(() => {})
                })
            },
            fetchState(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/state', {
                            params: {
                                countryid: this.filter.countryid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.states = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            fetchCity(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/city', {
                            params: {
                                stateid: this.filter.stateid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.cities = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },
            fetchFormGraphs(){

                this.preloadFormGraph = true

                let url = '/network/color-form?uuid='
                this.$api(this, (xhr) => {
                    xhr.get(url + this.genesisNode.networkid).then((r) => {

                        let data = r.data
                        this.preloadFormGraph = false
                        this.formgraphs = data.data

                    }).catch(() => {
                        this.preloadFormGraph = false
                    })
                })
            },
            goNetwork(network){

                if(network.hasOwnProperty('registered') && !network.registered){
                    return
                }

                return window.location.href = network.url
            },

            registerNetwork(network){

                network.loader = true
                let post = {
                    network: network.uuid
                }

                this.$api(this, (xhr) => {
                    return xhr.post('/pre-register', this.$qs.stringify(post)).then(async (r) => {
                        
                        let data = r.data
                        if(data.response){
                            network.registered = true
                            this.goNetwork(network)
                        }
                        network.loader = false
                    }).catch(() => {})
                })
            },

            shareAvatarUrl(network){
                return network.share && (network.share != null && network.share != '') ? config.uploadurl + network.share : ''
            },

            /**
             * Obtener banners
             */
            fetchBanner() {

                this.preloadBanner = true
                this.$api(this, (xhr) => {
                    xhr.get('/banner/home').then((r) => {

                        let data = r.data
                        this.preloadBanner = false
                        
                        if (data.response) {
                            this.banners = data.data
                        }

                    }).catch(() => {
                        this.preloadBanner = false
                    })
                })
            }
        }
    }
</script>